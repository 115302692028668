import { CheckboxProps } from "presentation/model/CheckBoxprops"
import { ProposalEnquiryConstant } from "../ProposalEnquiry/ProposalEnquiryConstant"
// import { ProposalEnquiryConstant } from "./ProposalEnquiryConstant"

interface CheckboxPropsList {
    option: CheckboxProps,
    checkboxList?: CheckboxProps[]
}

export const CriteriaDirectoryAllCheckboxOption: CheckboxProps = {key: 'all', name: 'All'}

export const CriteriaDirectoryCheckboxOption: CheckboxProps[] = [
    {key: 'companyCode', name: ProposalEnquiryConstant.Header.COMPANY_CODE},
    {key: 'proposalNo', name: ProposalEnquiryConstant.Header.PROPOSAL_NO},
    {key: 'operatingTml', name: ProposalEnquiryConstant.Header.OPERATING_TML},
    {key: 'effectiveDate', name: ProposalEnquiryConstant.Header.EFFECTIVE_DATE},    
    {key: 'tariffType', name: ProposalEnquiryConstant.Header.TAR_TYPE},
    {key: 'tariffCode', name: ProposalEnquiryConstant.Header.TAR_CODE},
    {key: 'activeInd', name: ProposalEnquiryConstant.Header.ACTIVE_IND},
    {key: 'proposalStatus', name: ProposalEnquiryConstant.Header.STATUS},    
]

// export const CriteriaDirectoryCheckboxOptionList: CheckboxPropsList = {
//     option: {key: 'proposalNo', name: ProposalEnquiryConstant.Header.PROPOSAL_NO},
//     checkboxList: CriteriaDirectoryCheckboxOption,
// }

// export const CriteriaDirectoryCheckboxOptionList: CheckboxPropsList[] = [
//     CriteriaDirectoryCheckboxOptionList,
//     CriteriaDirectoryChargeCheckboxOptionList,
//     CriteriaDirectoryTariffCoaCheckboxOptionList,
//     CriteriaDirectoryProposalCheckboxOptionList,
//     //CriteriaDirectoryContainerCheckboxOptionList,
// ]