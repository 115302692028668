import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
// import { ProposalEnquirySearchCriteria } from "presentation/constant/ProposalEnquiry/ProposalEnquirySearchCriteria";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { useCallback, useEffect, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
// import { isSearchCriteriaSet } from "../../ProposalEnquiryComp";
// import { ProposalEnquiryCriteriaEditPanel } from "./ProposalEnquiryCriteriaEditPanel/ProposalEnquiryCriteriaEditPanel";
import { isSearchCriteriaSet } from "../../ProposalEnquiryComp";
import { ProposalEnquiryCriteriaEditPanel } from "./ProposalEnquiryCriteriaEditPanel/ProposalEnquiryCriteriaEditPanel";
import { EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, ProposalEnquirySearchCriteria } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
import { ProposalEnquiryCriteriaPreviewPanel } from "../ProposalEnquiryCriteriaPreviewPanel/ProposalEnquiryCriteriaPreviewPanel";

export const ProposalEnquiryCriteriaPanel = () => {
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const proposalEnquiryVM = useProposalEnquiryVM();
    const isEditSearchCriteria = proposalEnquiryState.isEditSearchCriteria;
    const searchCriteria = proposalEnquiryState.searchCriteria;
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();

    const handleResetClick = () => {
        proposalEnquiryVM.onRemoveAllSearchCriteria();
    }

    const handleSearchClick = useCallback(async(searchCriteria:ProposalEnquirySearchCriteria, counter:number) => {   
        if(proposalEnquiryVM.validateSearchCriteria(searchCriteria)){
            messageBarVM.showWarining("Fill in at least one of the search criteria.");
            return;
        }     
        setIsLoading(true);
        proposalEnquiryVM.onSearch();

        await proposalEnquiryVM.fetchTableData(searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then((data) => {
            setIsLoading(false);          
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
        })
    },[proposalEnquiryVM, messageBarVM]);

    useEffect(() => {
        let timer: any = null;
        const cb = () => {
            const comField: any = document.querySelector('.im-charge-data-search-criteria-content input[name="co"]');
            if (comField) {
                comField.focus();
                timer && clearInterval(timer);
            }
        };

        timer = setInterval(cb, 0);
    }, []);

    return <div className='im-charge-data-search-criteria-panel'>
        <div className='im-charge-data-search-criteria-content'>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            
            {/* Search criteria form */}
            {
                isEditSearchCriteria &&
                <>
                    <ProposalEnquiryCriteriaEditPanel />
                </>
            }
            {
                !isEditSearchCriteria && !isSearchCriteriaSet(proposalEnquiryState.searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA) &&
                <>
                    <ProposalEnquiryCriteriaPreviewPanel />
                </>
            }

            {/* Search criteria no criteria selected */}
            {!isEditSearchCriteria && isSearchCriteriaSet(proposalEnquiryState.searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA) && <h4 style={{ margin: '0px' }}>No Criteria.</h4>}
        </div>
        <div className='im-charge-data-search-criteria-buttons'>           
            <>
                {isEditSearchCriteria &&
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={handleResetClick} />}
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={(e: any) => {                    
                    handleSearchClick(searchCriteria, proposalEnquiryState.searchCounter);}} />
            </>
        </div>
    </div>
};
