import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_PROPOSAL_ENQUIRY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ProposalEnquiry/ProposalEnquiryHeaderColumnDefinition";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { HPHTable, IconButton, Loader } from "veronica-ui-component/dist/component/core";


export const ProposalEnquiryTablePanel = () => {
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const isShowRightCriteriaPanel = proposalEnquiryState.isShowRightCriteriaPanel;
    const proposalEnquiryVM = useProposalEnquiryVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    const tableDoubleClicked = (data:any) => {
        // if (data) {
        //     customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REDIRECT_EVENT, 
        //         E_Custom_Dispatch_Event.REDIRECT_PROPOSAL_ENQUIRY_DETAIL_FROM_HEADER, 
        //         { userName: anaInfoState.userName,
        //             data: data.data,
        //         }
        //     );
        // }
        
        proposalEnquiryVM.openProposalDetail(data.data);
    }

    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_PROPOSAL_ENQUIRY_HEADER_COL_DEF.slice());
        
        // if (!proposalEnquiryState.selectedRows ||
        //     proposalEnquiryState.selectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        proposalEnquiryVM.updateSelectedRowsActivate(selectedRows);
      }, [proposalEnquiryVM])

    return <div className={`table-ag-gird im-charge-data-search-table-panel ag-theme-alpine`} style={{ position: 'relative' }}>
        <div className={`im-charge-data-search-table-panel-content ag-theme-alpine`}>
            {proposalEnquiryState.isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <HPHTable
                id='proposal-enquiry-table'
                columns={INITIAL_PROPOSAL_ENQUIRY_HEADER_COL_DEF.slice()}
                cacheBlockSize={30}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                showPaginator={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={true}
                gridHeight="customHeight"
                customHeight={"calc(100% - 10px)"}
                onRowDoubleClick={(e:any) => tableDoubleClicked(e)}                
                selectionMode={false}
                rowSelection={"multiple"}
                suppressRowClickSelection={false}
                onSelectionChanged={handleSelectionChange}
                rowHeight={28}
                rowBuffer={20}
                ref={gridRef}
                data={transferRowData(proposalEnquiryState.proposals??[])}

                columnSettingIconList={
                    <>
                        <IconButton fileName={isShowRightCriteriaPanel ? "Icon-angle-left" : "Icon-angle-right"} size="medium" toolTipText={isShowRightCriteriaPanel ? "Close Right Panel" : "Open Right Panel"} toolTipArrow={false} onClick={proposalEnquiryVM.onHideRightCriteriaPanel} />
                    </>
                }
            />
        </div>
    </div>;
}