import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ProposalEnquiryConstant } from "./ProposalEnquiryConstant";
import { ProposalEnquiryEntity } from "domain/entity/ProposalEnquiry/ProposalEnquiryEntity";


const PROPOSAL_ENQ_HDR_CONST = ProposalEnquiryConstant.Header;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
let timeFieldList:string[] = [];



export const INITIAL_PROPOSAL_ENQUIRY_HEADER_COL_DEF: any[] = [
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.COMPANY_CODE,
        field: 'companyCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
        checkboxSelection: true,
        headerCheckboxSelection: true,    
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.PROPOSAL_NO,
        field: 'proposalNo',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.SEQ,
        field: 'seq',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 100,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.CURRENCY,
        field: 'currencyCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.FORWARDER,
        field: 'forwarderCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.PROPOSAL_TYPE,
        field: 'proposalType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.STATUS,
        field: 'proposalStatus',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 200,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.ACTIVE_IND,
        field: 'activeInd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        filter: false,
        width: 130,
    },
    {
        headerName: PROPOSAL_ENQ_HDR_CONST.REMARKS,
        field: 'remarks',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: false,
        width: 1000,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, timeFieldList, cellRenderers);
});

export const transferRowData = (data:ProposalEnquiryEntity[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal<ProposalEnquiryEntity>(data, dateFieldList, dateTimeFieldList, timeFieldList, externalFnctions);
}